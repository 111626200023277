import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import RegisterPage from './components/RegisterPage';
import LoginPage from './components/LoginPage';
import VerifyOtpPage from './components/VerifyOtpPage';
import CreatePinPage from './components/CreatePinPage';
import EnterPinPage from './components/EnterPinPage';
import Dashboard from './components/Dashboard';
import BoardPage from './board/BoardPage';
import TaskPage from './board/TaskPage';
import InvitePage from './board/InvitePage';
import WalletPage from './board/WalletPage';
import GetUserInfoButton from './components/GetUserInfoButton';
import LoadingPage from './components/LoadingPage';
import CreateWallet from './components/CreateWallet';
import EmailLoginPage from './components/EmailLoginPage';
import WalletCreator from './WalletCreator';
import HDWalletGenerator from './CreateMultipleWallet';
import WalletManager from './WalletManager';
import TextToSpeech from './TextToSpeech';
import TaskScreen from './board/TaskScreen';


function App() {

 


  return (
    <Router>
      <Routes>
       
      <Route path="/" element={<LoadingPage />} />
      <Route path="/tasks" element={<TaskScreen />} />
      <Route path="/text" element={<TextToSpeech />} />
      <Route path="/manager" element={<WalletManager />} />
      <Route path="/samplewallet" element={<WalletCreator/>} />
      <Route path="/mwallet" element={<HDWalletGenerator/>} />
      <Route path="emaillogin" element={<EmailLoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/verifyemail" element={<VerifyOtpPage />} />
        <Route path="/createpin" element={<CreatePinPage />} />
        <Route path="/validate" element={<EnterPinPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/board" element={<BoardPage />} />
        <Route path="/task" element={<TaskPage />} />
        <Route path="/invite" element={<InvitePage />} />
        <Route path="/wallet" element={<WalletPage />} /> 
        <Route path="/Landing" element={<LandingPage />} />
        <Route path="/tele" element={<GetUserInfoButton />} />
        <Route path="/createwallet" element={<CreateWallet/>} />

      </Routes>
    </Router>
  );
}

export default App;
