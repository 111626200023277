import React, { useState } from 'react';

const CreateWallet = () => {
    const [username, setUsername] = useState('');
    const [currency, setCurrency] = useState('');
    const [network, setNetwork] = useState('');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const handlePostRequest = async () => {
        const url = 'https://99ogxuh0td.execute-api.eu-north-1.amazonaws.com/cryptomdeploy/payment';

        const data = {
            username,
            currency,
            network
        };

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if needed
                },
                body: JSON.stringify(data),
            });

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await res.json();
            setResponse(result);
            setError(null); // Clear any previous errors
        } catch (error) {
            setError(error.message);
            setResponse(null); // Clear any previous response
        }
    };

    return (
        <div>
            <h1>Send Data</h1>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handlePostRequest();
                }}
            >
                <div>
                    <label>
                        Username:
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Currency:
                        <input
                            type="text"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Network:
                        <input
                            type="text"
                            value={network}
                            onChange={(e) => setNetwork(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <button type="submit">Submit</button>
            </form>
            {response && <div>Response: {JSON.stringify(response)}</div>}
            {error && <div>Error: {error}</div>}
        </div>
    );
};

export default CreateWallet;
