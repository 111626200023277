import React, { useState, useRef } from 'react';
import './CreatePinPage.css';

const CreatePinPage = () => {
  const [pin, setPin] = useState(['', '', '', '', '', '']); // Array to store PIN digits
  const [confirmPin, setConfirmPin] = useState(['', '', '', '', '', '']); // Array to store Confirm PIN digits
  const [isPinConfirmed, setIsPinConfirmed] = useState(false);
  const pinInputRefs = useRef([]); // Array to hold references to PIN input fields
  const confirmPinInputRefs = useRef([]); // Array to hold references to Confirm PIN input fields
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e, index, isConfirmPin) => {
    const { value } = e.target;
    const newPin = isConfirmPin ? [...confirmPin] : [...pin];
    newPin[index] = value.slice(0, 1); // Allow only one digit per field
    if (isConfirmPin) {
      setConfirmPin(newPin);
    } else {
      setPin(newPin);
    }

    // Move to the next input field if the current one is filled
    if (value && index < newPin.length - 1) {
      const nextInputRef = isConfirmPin ? confirmPinInputRefs.current[index + 1] : pinInputRefs.current[index + 1];
      if (nextInputRef) {
        nextInputRef.focus();
      }
    }
  };

  const handleKeyDown = (e, index, isConfirmPin) => {
    const inputRefs = isConfirmPin ? confirmPinInputRefs.current : pinInputRefs.current;

    if (e.key === 'Backspace') {
      // Handle backspace in the input field
      if (!inputRefs[index].value && index > 0) {
        // Move focus to the previous input field if current field is empty and backspace is pressed
        inputRefs[index - 1].focus();
      }
    } else if (e.key === 'ArrowLeft' && index > 0 && e.target.selectionStart === 0) {
      // Move focus to the previous input field when arrow left is pressed and cursor is at the start
      inputRefs[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < inputRefs.length - 1 && e.target.selectionStart === e.target.value.length) {
      // Move focus to the next input field when arrow right is pressed and cursor is at the end
      inputRefs[index + 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    const pinString = pin.join('');
    const confirmPinString = confirmPin.join('');
    
    try {
      if (pinString.length !== pin.length || confirmPinString.length !== confirmPin.length) {
        throw new Error('Please enter the complete PIN.');
      }
      if (pinString !== confirmPinString) {
        throw new Error('PINs do not match.');
      }
      setIsPinConfirmed(true);
      // Simulate PIN creation process (replace with actual logic)
      // await createPin(pinString);
      setMessage('PIN created successfully.');
    } catch (err) {
      setError(err.message || 'Failed to create PIN. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="background">
    <div className="create-pin-page">
      <h1>Create PIN</h1>
      <form onSubmit={handleSubmit}>
        <div className="pin-section">
          <h2>Create PIN</h2>
          <div className="pin-container">
            {pin.map((value, index) => (
              <input
                key={index}
                type="text"
                value={value}
                onChange={(e) => handleChange(e, index, false)}
                onKeyDown={(e) => handleKeyDown(e, index, false)}
                maxLength="1"
                ref={(el) => (pinInputRefs.current[index] = el)}
                autoFocus={index === 0} // Focus the first input by default
              />
            ))}
          </div>
        </div>
        <div className="pin-section">
          <h2>Confirm PIN</h2>
          <div className="pin-container">
            {confirmPin.map((value, index) => (
              <input
                key={index + 6} // Use an offset to distinguish keys
                type="text"
                value={value}
                onChange={(e) => handleChange(e, index, true)}
                onKeyDown={(e) => handleKeyDown(e, index, true)}
                maxLength="1"
                ref={(el) => (confirmPinInputRefs.current[index] = el)}
              />
            ))}
          </div>
        </div>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Creating PIN...' : 'Create PIN'}
        </button>
      </form>
    </div>
    </div>
  );
};

export default CreatePinPage;
