import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './InvitePage.css'; // Ensure this CSS file exists
import referralLogo from '../components/images/referral.png';
import copyLogo from '../components/images/copy.png';


const InvitePage = () => {
  const [referralCount, setReferralCount] = useState('');
  const [copyMessage, setCopyMessage] = useState('');
  const [data, setData] = useState('');

  
  const navigate = useNavigate();
  const location = useLocation();
 // Initialize user
 const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));
  // Tab 
  // Tab 
  const [activeTab, setActiveTab] = useState('home'); // Active tab state

useEffect(() => {
  const currentPath = location.pathname.split('/')[1]; // Get the path after the first '/'
  setActiveTab(currentPath || 'home'); // Default to 'home' if path is empty
}, [location]);

const handleTabClick = (tab) => {
  setActiveTab(tab);
  navigate(`/${tab}`);
};


// Fetch user data
useEffect(() => {
  const fetchData = async () => {
    try {
      const username = await user.username;
      const response = await axios.get(`https://ayomitech.com/earncoin/api-php/getdata.php?username=${username}`);
      setData(response.data.data[0]); // Set the data state
      //console.log(data)
    } catch (error) {
      // Handle error
    }
  };

  fetchData(); // Call the fetch function
}, [user]);



  const copyToClipboard = () => {
    navigator.clipboard.writeText(user?.username)
      .then(() => {
        setCopyMessage('Referral code copied!');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };



  

  return (
    <div className="invite-page">
      <header>
        <div className="header-content">
          <h1 style={{color:'white'}}>Invite Friends</h1>
          <p style={{color:'yellow', display:'flex', flexDirection:'row'}}>
            You have  {data?.referral_count} referrals!</p>
        </div>
      </header>
       
      
        <section className="invite-content">
          <img src={referralLogo} alt="Referral" className="referral-image" />
          <p style={{display:'flex', textAlign:'center', color:'blue', fontSize:16, fontWeight:'bold'}}>+2000 Point</p>
          <div className="referral-instructions">
            <h3>How to Refer:</h3>
            <ol>
              <li>Share your referral code with friends.</li>
              <li>When they sign up using your code, you both earn rewards!</li>
            </ol>
          </div>
          <div className="referral-code-container">
            <p>Your Referral Code:</p>
            <div className="referral-code">
              <span id="code">{data?.referral_code}</span>
              <button onClick={copyToClipboard} title="Copy Code">
                <img src={copyLogo} alt="Copy Icon" /> Copy
              </button>
            </div>
          </div>
          {copyMessage && <p className="copy-message">{copyMessage}</p>}
        </section>
     
      
     
        
      <div className="tabs">
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>
              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>

     
    </div>
  );
};

export default InvitePage;
