import React, { useState, useRef } from 'react';
import './VerifyOtpPage.css';

const VerifyOtpPage = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']); // Array to store OTP digits
  const inputRefs = useRef([]); // Array to hold references to OTP input fields
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1); // Allow only one digit per field
    setOtp(newOtp);

    // Move to the next input field if the current one is filled
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      // Move focus to the previous input field if current field is empty and backspace is pressed
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    const otpString = otp.join('');
    
    try {
      // Simulate OTP verification process (replace with actual logic)
      if (otpString.length !== otp.length) {
        throw new Error('Please enter the complete OTP.');
      }
      // await verifyOtp(otpString);
      setMessage('OTP verified successfully. Redirecting...');
    } catch (err) {
      setError(err.message || 'Failed to verify OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="background">
    <div className="verify-otp-page">
      <h1>Verify OTP</h1>
      <form onSubmit={handleSubmit}>
        <div className="otp-container">
          {otp.map((value, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength="1"
              ref={(el) => (inputRefs.current[index] = el)} // Set ref for each input
              autoFocus={index === 0} // Focus the first input by default
            />
          ))}
        </div>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Verifying...' : 'Verify OTP'}
        </button>
      </form>
    </div>
    </div>
  );
};

export default VerifyOtpPage;
