import React, { useState, useEffect } from 'react';
import './board.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import goldLogo from '../components/images/dollar.png';


const BoardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();


  // Tab 
  const [activeTab, setActiveTab] = useState('board'); // Active tab state

useEffect(() => {
  const currentPath = location.pathname.split('/')[1]; // Get the path after the first '/'
  setActiveTab(currentPath || 'board'); // Default to 'home' if path is empty
}, [location]);

const handleTabClick = (tab) => {
  setActiveTab(tab);
  navigate(`/${tab}`);
};

  const [data, setData] = useState(null);
  const [balance, setBalance] = useState(10.00);
  const [clickCount, setClickCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [level, setLevel] = useState(1);
  const [message, setMessage] = useState('');
  const [theme, setTheme] = useState('dark');
  const [loading, setLoading] = useState(true);
  
  const [basePoint, setBasePoint] = useState(0);
  const [emojis, setEmojis] = useState([]);

  const user = location.state?.user || JSON.parse(localStorage.getItem('userData')) || null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://ayomitech.com/earncoin/api-php/getdata.php?username=${user.username}`);
        const userData = response.data.data[0];
        setData(userData);
        setBasePoint(userData?.points);
        updateLevelBasedOnBalance(userData?.points);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [user]);

  const updateLevelBasedOnBalance = (newBasePoint) => {
    const thresholds = [0, 50000, 100000, 500000, 1000000, 2000000, 3000000, 5000000, 6000000, 7000000];
    const newLevel = thresholds.reduce((acc, threshold, index) => {
      return newBasePoint >= threshold ? index + 1 : acc;
    }, 1);
    setLevel(Math.min(newLevel, 10));
  };

  const claimCoin = async () => {
    if (!user?.username) {
      setMessage('Username is not available');
      return;
    }

    const newPoints = balance / 2;
    const userData = { username: user.username, newPoints };

    try {
      const response = await fetch(`https://ayomitech.com/earncoin/api-php/updatepoint.php`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
      });
      const result = await response.json();
      if (result) {
        setBalance(0.00);
        setMessage('');
      } else {
        setMessage('Network error');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleTap = () => {
    setClickCount(prevClickCount => {
      const newClickCount = prevClickCount + 1;

      if (newClickCount >= 500) {
        setMessage('Return in 2 hours to earn more points');
        setIsButtonDisabled(true);
        localStorage.setItem('disableTimestamp', Date.now().toString());

        setTimeout(() => {
          setIsButtonDisabled(false);
          setClickCount(0);
          localStorage.removeItem('disableTimestamp');
        }, 2000 * 60 * 60);
      }

      return newClickCount;
    });

    setEmojis(prevEmojis => [
      ...prevEmojis,
      { id: Date.now(), emoji: '💰' }
    ]);

    setBalance(prevBalance => {
      const newBalance = prevBalance + 1;
      updateLevelBasedOnBalance(basePoint + 1);
      return newBalance;
    });
  };

  const getBarColor = () => {
    switch (level) {
      case 1: return { color: '#cd7f32', label: 'Starter' }; // Bronze
      case 2: return { color: '#cd7f32', label: 'Bronze' }; // Bronze
      case 3: return { color: '#c0c0c0', label: 'Silver' }; // Silver
      case 4: return { color: '#ffd700', label: 'Gold' }; // Gold
      case 5: return { color: '#e5e4e2', label: 'Platinum' }; // Platinum
      case 6: return { color: '#b9fbc0', label: 'Diamond' }; // Diamond
      case 7: return { color: '#50C878', label: 'Emerald' }; // Emerald
      case 8: return { color: '#FF5733', label: 'Ruby' }; // Ruby
      case 9: return { color: '#6A5ACD', label: 'Sapphire' }; // Sapphire
      case 10: return { color: '#FF0000', label: 'Legend' }; // Red
      default: return { color: '#e0e0e0', label: 'Unknown' }; // Default color
    }
  };

  const maxPointsForLevel = [0, 50000, 100000, 500000, 1000000, 2000000, 3000000, 5000000, 6000000, 7000000]; // Max points for each level

  const getProgressBarWidth = () => {
    const maxPoints = maxPointsForLevel[level - 1]; // Get max points for current level
    return `${(basePoint / maxPoints) * 100}%`; // Calculate width
  };
  
  const { color, label } = getBarColor();

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmojis([]);
    }, 1000);
    return () => clearTimeout(timer);
  }, [emojis]);

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  
  

  return (
    <div className="container">
      <header>
            <div className="header-content">
              <h5>{user?.username}</h5>
              
            </div>

          <div className="sub-head">
              <span style={{color:`${color}`}}>{label}</span>
              <div className="bar-con">
                <div className="ba" style={{ width: getProgressBarWidth(), backgroundColor: getBarColor() }}></div>
              </div>
              <p style={{color:`${color}`}}> {level}/10</p>

              <div className="point-con">
                <img src={goldLogo} alt="USDT Logo" className="coin-logo" />
                <span style={{color:`${color}`}}>Points: {basePoint}</span>
              </div>

           </div>
          
      </header>

      <div className='claim-section'>
               <div className='coin-balance'>
                  <img src={goldLogo} alt="logo" className="coin-logo" />
                  <span className="coin-emoji"> {balance.toFixed(2)} </span>
                    {balance >= 50 && !isButtonDisabled && (
                      <button onClick={claimCoin} className="claim">
                        Claim
                      </button>
                    )}
                </div>
              <div>{message}</div>
            </div>
      
      
            <section className="board-content">
             <div
                className={`circle-icon ${isButtonDisabled ? 'disabled' : ''}`}
                onClick={!isButtonDisabled ? handleTap : null}
                style={{
                  backgroundImage: `url(${goldLogo})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: 200,
                  height: 200,
                  marginTop: 30, 
                 
              
                }}
              >
                {emojis.map((emoji) => (
                  <span key={emoji.id} className="coin-emoji animate-coin">
                    {emoji.emoji}
                  </span>
                ))}
              </div>
            </section>
        
       
      
     
        
        <div className="tabs">
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>
              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>

    
    </div>
  );
};

export default BoardPage;
