import React from 'react';
import './Dashboard.css';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const location = useLocation();
  const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h1>Crypto Dashboard</h1>
      </header>
      <div className="dashboard-topbar">
        <div className="balance-info">
          <h2>Welcome, {user?.username || 'User'}</h2>
          <p>$15,000.00</p>
        </div>
        <div className="action-buttons">
          <button className="btn"><i className="fas fa-money-bill-wave"></i> Withdraw</button>
          <button className="btn"><i className="fas fa-arrow-down"></i> Receive</button>
          <button className="btn"><i className="fas fa-arrow-up"></i> Deposit</button>
          <button className="btn"><i className="fas fa-box"></i> Assets</button>
        </div>
      </div>
      <main className="dashboard-main">
        <section className="crypto-summary">
          <h2>Crypto Summary</h2>
          <div className="summary-card">
            <h3><i className="fab fa-bitcoin"></i> Bitcoin (BTC)</h3>
            <p>Price: $50,000</p>
            <p>24h Change: +2.5%</p>
          </div>
          <div className="summary-card">
            <h3><i className="fab fa-ethereum"></i> Ethereum (ETH)</h3>
            <p>Price: $3,500</p>
            <p>24h Change: -1.2%</p>
          </div>
        </section>
        <section className="crypto-chart">
          <h2>Market Trend</h2>
          <div className="chart-placeholder">Chart goes here</div>
        </section>
      </main>
      <footer className="dashboard-footer">
        <p>&copy; 2024 Crypto Dashboard</p>
      </footer>
    </div>
  );
};

export default Dashboard;
