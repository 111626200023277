import React, { useState } from 'react';
import { ethers } from 'ethers';

const WalletCreator = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [usdtBalance, setUsdtBalance] = useState('');
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');

  const createWallet = () => {
    const wallet = ethers.Wallet.createRandom();
    setWalletAddress(wallet.address);
    setPrivateKey(wallet.privateKey);
    setUsdtBalance('');
    setError('');
  };

  const checkUsdtBalance = async () => {
    if (!walletAddress || !privateKey) return;

    setLoading(true);
    setError('');

    try {
      const provider = new ethers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
      const wallet = new ethers.Wallet(privateKey, provider);

      const usdtAddress = '0x55d398326f99059ff775485246999027b3197955'; // USDT contract address on BSC
      const usdtAbi = ["function balanceOf(address owner) view returns (uint256)"];
      const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, wallet);

      const balance = await usdtContract.balanceOf(wallet.address);
      const formattedBalance = ethers.formatUnits(balance, 18); // USDT has 18 decimals
      setUsdtBalance(formattedBalance);
    } catch (err) {
      setError('Failed to fetch balance. Please check your wallet address or network.');
    } finally {
      setLoading(false);
    }
  };

  const sendUsdt = async () => {
    if (!walletAddress || !privateKey || !recipient || !amount) return;

    setLoading(true);
    setError('');
    setTransactionStatus('');

    try {
      const provider = new ethers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
      const wallet = new ethers.Wallet(privateKey, provider);

      const usdtAddress = '0x55d398326f99059ff775485246999027b3197955'; // USDT contract address on BSC
      const usdtAbi = ["function transfer(address to, uint256 amount) returns (bool)"];
      const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, wallet);

      const amountInUnits = ethers.parseUnits(amount, 18); // Convert amount to the appropriate units

      const transactionResponse = await usdtContract.transfer(recipient, amountInUnits);
      setTransactionStatus(`Transaction sent! Hash: ${transactionResponse.hash}`);

      await transactionResponse.wait(); // Wait for the transaction to be mined
      setTransactionStatus(`Transaction confirmed! Hash: ${transactionResponse.hash}`);
    } catch (err) {
      setError('Transaction failed. Please check your input values.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(privateKey).then(() => {
      alert('Private key copied to clipboard!');
    });
  };

  return (
    <div>
      <h1>Create a USDT Wallet on BSC</h1>
      <button onClick={createWallet}>Create Wallet</button>
      {walletAddress && (
        <div>
          <h2>Wallet Created!</h2>
          <p><strong>Wallet Address:</strong> {walletAddress}</p>
          <p>
            <strong>Private Key:</strong>
            <button onClick={copyToClipboard}>Copy</button>
          </p>
          <button onClick={checkUsdtBalance} disabled={loading}>
            {loading ? 'Checking Balance...' : 'Check USDT Balance'}
          </button>

          {usdtBalance && <h3>Your USDT Balance: {usdtBalance} USDT</h3>}

          <h3>Send USDT</h3>
          <input
            type="text"
            placeholder="Recipient Address"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
          <input
            type="text"
            placeholder="Amount (USDT)"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button onClick={sendUsdt} disabled={loading}>
            {loading ? 'Sending...' : 'Send USDT'}
          </button>
          {transactionStatus && <p>{transactionStatus}</p>}
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default WalletCreator;
