// GetUserInfoButton.js
import React from 'react';

const GetUserInfoButton = () => {
  const handleClick = async () => {
    try {
      const response = await fetch('https://jb1selaz0a.execute-api.eu-north-1.amazonaws.com/newdeploy/telegramlogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify() // Replace with the actual chat ID
      });

      const data = await response.json();
      if (data) {
        alert('User info sent to Telegram!');
      } else {
        alert('Failed to send user info.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    }
  };

  return (
    <button onClick={handleClick}>
      Get User Info
    </button>
  );
};

export default GetUserInfoButton;
