import React, { useState, useRef } from 'react';
import './EnterPinPage.css';

const EnterPinPage = () => {
  const [pin, setPin] = useState(['', '', '', '', '', '']); // Array to store PIN digits
  const inputRefs = useRef([]); // Array to hold references to PIN input fields
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newPin = [...pin];
    newPin[index] = value.slice(0, 1); // Allow only one digit per field
    setPin(newPin);

    // Move to the next input field if the current one is filled
    if (value && index < pin.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !pin[index] && index > 0) {
      // Move focus to the previous input field if current field is empty and backspace is pressed
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    const pinString = pin.join('');
    
    try {
      // Simulate PIN submission process (replace with actual logic)
      if (pinString.length !== pin.length) {
        throw new Error('Please enter the complete PIN.');
      }
      // await submitPin(pinString);
      setMessage('PIN entered successfully. Redirecting...');
    } catch (err) {
      setError(err.message || 'Failed to enter PIN. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="background">
    <div className="enter-pin-page">
      <h1>Enter PIN</h1>
      <form onSubmit={handleSubmit}>
        <div className="pin-container">
          {pin.map((value, index) => (
            <input
              key={index}
              id={`pin-${index}`}
              type="text"
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength="1"
              ref={(el) => (inputRefs.current[index] = el)} // Set ref for each input
              autoFocus={index === 0} // Focus the first input by default
            />
          ))}
        </div>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit PIN'}
        </button>
      </form>
    </div>
    </div>
  );
};

export default EnterPinPage;
