import React, { useEffect } from 'react';
import './LandingPage.css';
import axios from 'axios';
// Importing the logos
import usdtLogo from './images/usdt.png';
import bitcoinLogo from './images/bitcoin-logo.png';
import ethLogo from './images/eth-logo.png';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {

  const navigate = useNavigate();


  return (
    <div className="background">
    <div className="landing-page">
      <header className="header">
        <div className="header-content">
          <h1>Crypto Landing</h1>
          <p>Welcome to the future of finance</p>
          <div className="header-buttons">
            <a href='/login'><button  className="btn btn-login">
              Login
            </button></a>
            <a href='/register'> <button  className="btn btn-get-started">
              Get Started
            </button></a>
          </div>
        </div>
      </header>
      <section className="crypto-features">
        <h2>Featured Cryptocurrencies</h2>
        <div className="crypto-list">
          <div className="crypto-item">
            <img src={usdtLogo} alt="USDT Logo" className="crypto-logo" />
            <p>Tether (USDT)</p>
          </div>
          <div className="crypto-item">
            <img src={bitcoinLogo} alt="Bitcoin Logo" className="crypto-logo" />
            <p>Bitcoin (BTC)</p>
          </div>
          <div className="crypto-item">
            <img src={ethLogo} alt="Ethereum Logo" className="crypto-logo" />
            <p>Ethereum (ETH)</p>
          </div>
        </div>
      </section>
    </div>
    </div>
  );
};

export default LandingPage;
