import React, { useState } from 'react';
import './EmailLoginPage.css';
import { useNavigate } from 'react-router-dom';

const EmailLoginPage = () => {
  const [email, setEmail] = useState('');
  const [code, setcode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();



  const getOtp = async (e) => {
    e.preventDefault();


    if (!email) {
        setMessage('Email is required');
        setTimeout(() => {
          setMessage('');
        }, 2000);
        return; // Exit the function early if email is empty
      }
    
    // Create the payload
    const userEmail = {
      email,
    };

    

    try {
      const response = await fetch('https://ayomitech.com/verify/otp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userEmail)
      });
      
      const result = await response.json();
      if (result) {
        if (result.Message === 'update') {
          setMessage('OTP sent to your email');
          setTimeout(() => {
            setMessage('');
        }, 2000);
        } else if (result.message === 'insert') {
          setMessage('OTP sent to your email');
          setTimeout(() => {
            setMessage('');
        }, 2000);
         
      } else {
        setMessage('Connection error');
        setTimeout(() => {
            setMessage('');
        }, 2000);
      }
    }
 
    } catch (error) {
        setMessage('An error occurred: ' + error.message);
        setTimeout(() => {
          setMessage('');
        }, 2000);
    }

  };


  const verifyCode = async (e) => {
    e.preventDefault();
    
    // Create the payload
    const userData = {
      email,
      code
    };

    try {
      const response = await fetch('https://ayomitech.com/verify/verify-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });
     
      const info = await response.json();
    
      if (info) {
        if (info[0].Message === 'NotSuccessful') {
            setMessage('Invalid OTP');
           
              // Clear message after 2 seconds
              setTimeout(() => {
                  setMessage('');
              }, 2000);
  
          } else if (info[0].Message === 'ValidOTP') {
          
              navigate('/register', { state: { email } });
  
          } else if (info[0].Message === 'InvalidOTP') {
            setMessage('OTP Error');
            setTimeout(() => {
              setMessage('');
          }, 2000);
          }
        } else {
          setMessage('Check our connect and try again.');
          setTimeout(() => {
              setMessage('');
          }, 2000);
        }
    } catch (error) {
      console.error('An error occurred:', error);
      //setMessage('An error occurred: ' + error.message);
    }
  };





  

  return (
    <div className="background">
      <div className="register-page">
        <h1>Start Mining</h1>
        {message && <label className='error-message'>{message}</label>}
        <form onSubmit={verifyCode}>
          <div className="form-group">
            <p htmlFor="email">Email:</p>
            <input 
              id="email"
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <p htmlFor="code">OTP Code:</p>
            <input 
              id="code"
              type="number" 
              value={code} 
              onChange={(e) => setcode(e.target.value)} 
              required 
            />
             <button type="submit"  onClick={getOtp} className="otp-btn">Get OTP</button>
          </div>
         
          <button type="submit"  className="submit-btn">Verify</button>
        </form>
       
      </div>
    </div>
  );
};

export default EmailLoginPage;
