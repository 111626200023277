import React, { useState } from 'react';
import { ethers } from 'ethers';

const WalletCreator = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [usdtBalance, setUsdtBalance] = useState('');
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');

  const createWallet = () => {
    const wallet = ethers.Wallet.createRandom();
    setWalletAddress(wallet.address);
    setPrivateKey(wallet.privateKey);
    setUsdtBalance('');
    setError('');
  };

  const checkUsdtBalance = async () => {
    // Check balance function (as previously defined)
  };

  const sendTransaction = async () => {
    if (!walletAddress || !privateKey || !recipient || !amount) return;

    setLoading(true);
    setError('');
    setTransactionStatus('');

    try {
      const provider = new ethers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
      const wallet = new ethers.Wallet(privateKey, provider);

      // Sending BNB
      const tx = {
        to: recipient,
        value: ethers.parseEther(amount), // Convert amount to wei
      };

      const transactionResponse = await wallet.sendTransaction(tx);
      setTransactionStatus(`Transaction sent! Hash: ${transactionResponse.hash}`);

      // Wait for transaction to be mined
      await transactionResponse.wait();
      setTransactionStatus(`Transaction confirmed! Hash: ${transactionResponse.hash}`);
    } catch (err) {
      setError('Transaction failed. Please check your input values.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Create a USDT Wallet on BSC</h1>
      <button onClick={createWallet}>Create Wallet</button>
      {walletAddress && (
        <div>
          <h2>Wallet Created!</h2>
          <p><strong>Wallet Address:</strong> {walletAddress}</p>
          <p>
            <strong>Private Key:</strong>
           
          </p>
          <button onClick={checkUsdtBalance} disabled={loading}>
            {loading ? 'Checking Balance...' : 'Check USDT Balance'}
          </button>

          <h3>Send Transaction</h3>
          <input
            type="text"
            placeholder="Recipient Address"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
          <input
            type="text"
            placeholder="Amount (BNB)"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button onClick={sendTransaction} disabled={loading}>
            {loading ? 'Sending...' : 'Send BNB'}
          </button>
          {transactionStatus && <p>{transactionStatus}</p>}
        </div>
      )}
      {usdtBalance && <h3>Your USDT Balance: {usdtBalance} USDT</h3>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default WalletCreator;
