// src/Wallet.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

const TaskScreen = () => {
    const [wallet, setWallet] = useState(null);
    const [balance, setBalance] = useState(null);
    const [error, setError] = useState('');
    const [web3, setWeb3] = useState(null);

    // Connect to BSC
    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
        } else {
            setError('MetaMask is not installed');
        }
    }, []);

    const createWallet = async () => {
        try {
            const randomWallet = web3.eth.accounts.create();
            setWallet(randomWallet);
            setBalance(null);
            setError('');
        } catch (err) {
            setError('Error creating wallet: ' + err.message);
        }
    };

    const checkBalance = async () => {
        if (!wallet) {
            setError('Please create or connect a wallet first.');
            return;
        }
        try {
            const balanceWei = await web3.eth.getBalance(wallet.address);
            const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
            setBalance(balanceEth);
            setError('');
        } catch (err) {
            setError('Error fetching balance: ' + err.message);
        }
    };

    const connectWallet = async () => {
        if (!window.ethereum) {
            setError('MetaMask is not installed');
            return;
        }

        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];
            setWallet({ address: account });
            setBalance(null);
            setError('');
        } catch (err) {
            setError('Error connecting to wallet: ' + err.message);
        }
    };

    return (
        <div>
            <h1>BSC Wallet</h1>
            {wallet ? (
                <div>
                    <h2>Wallet Address: {wallet.address}</h2>
                    <button onClick={checkBalance}>Check Balance</button>
                    {balance !== null && <h3>Balance: {balance} BNB</h3>}
                </div>
            ) : (
                <div>
                    <button onClick={createWallet}>Create New Wallet</button>
                    <button onClick={connectWallet}>Connect MetaMask Wallet</button>
                </div>
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default TaskScreen;
