import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const WalletManager = () => {
  const [privateKey, setPrivateKey] = useState('');
  const [encryptedKey, setEncryptedKey] = useState('');
  const [decryptedKey, setDecryptedKey] = useState('');

  // Get the encryption key from the environment variable
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
 
  // Function to encrypt the private key
  const encryptPrivateKey = (key) => {
   
    const iv = CryptoJS.lib.WordArray.random(16); // Generate random IV
    const encrypted = CryptoJS.AES.encrypt(key, encryptionKey, {
      iv: iv
    });
    return `${encrypted.toString()}::${iv.toString()}`;
    
  };

  // Function to decrypt the private key
  const decryptPrivateKey = (encryptedData) => {
    const [encryptedKey, iv] = encryptedData.split('::');
    const decrypted = CryptoJS.AES.decrypt(encryptedKey, encryptionKey, {
      iv: CryptoJS.enc.Hex.parse(iv)
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const handleEncrypt = () => {
    const encrypted = encryptPrivateKey(privateKey);
    setEncryptedKey(encrypted);
  };

  const handleDecrypt = () => {
    const decrypted = decryptPrivateKey(encryptedKey);
    setDecryptedKey(decrypted);
  };

  return (
    <div>
      <h1>Wallet Manager</h1>
      <input
        type="text"
        placeholder="Enter Private Key"
        value={privateKey}
        onChange={(e) => setPrivateKey(e.target.value)}
      />
      <button onClick={handleEncrypt}>Encrypt Private Key</button>
      <p>Encrypted Key: {encryptedKey}</p>

      <input
        type="text"
        placeholder="Enter Encrypted Key"
        value={encryptedKey}
        onChange={(e) => setEncryptedKey(e.target.value)}
      />
      <button onClick={handleDecrypt}>Decrypt Private Key</button>
      <p>Decrypted Key: {decryptedKey}</p>
    </div>
  );
};

export default WalletManager;
