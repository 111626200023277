import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './TaskPage.css';
import youtubeLogo from '../components/images/youtube2.png';
import telegramLogo from '../components/images/telegram.png';
import tiktokLogo from '../components/images/tiktok.png';







const TaskPage = () => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTittle, setModalTitle] = useState('');
  const [modalUrl, setModalUrl] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [disabledItems, setDisabledItems] = useState(Array(12).fill(false));
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [message, setMessage] = useState('');
  const [data, setData] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
 // Initialize user
 const user = location.state?.user || JSON.parse(localStorage.getItem('userData'));
  // Tab 
  const [activeTab, setActiveTab] = useState('task'); // Active tab state

useEffect(() => {
  const currentPath = location.pathname.split('/')[1]; // Get the path after the first '/'
  setActiveTab(currentPath || 'task'); // Default to 'home' if path is empty
}, [location]);

const handleTabClick = (tab) => {
  setActiveTab(tab);
  navigate(`/${tab}`);
};

// Fetch user data
useEffect(() => {
  const fetchData = async () => {
    try {
      const username = await user.username;
      const response = await axios.get(`https://ayomitech.com/earncoin/api-php/getdata.php?username=${username}`);
      setData(response.data.data[0]); // Set the data state
      //console.log(data)
    } catch (error) {
      // Handle error
    }
  };

  fetchData(); // Call the fetch function
}, [user]);

  useEffect(() => {
    // Check localStorage for saved expiration time
    const savedTime = localStorage.getItem('expirationTime');
    if (savedTime) {
      const timeLeft = parseInt(savedTime, 10) - Date.now();
      if (timeLeft > 0) {
        setDisabledItems(prev => {
          const newDisabledItems = [...prev];
          const index = parseInt(localStorage.getItem('disabledIndex'), 10);
          newDisabledItems[index] = true;
          return newDisabledItems;
        });

        // Start countdown
        const countdown = setInterval(() => {
          const updatedTimeLeft = timeLeft - 1000;
          if (updatedTimeLeft <= 0) {
            clearInterval(countdown);
            const updatedDisabledItems = [...disabledItems];
            const index = parseInt(localStorage.getItem('disabledIndex'), 10);
            updatedDisabledItems[index] = false;
            setDisabledItems(updatedDisabledItems);
            localStorage.removeItem('expirationTime');
            localStorage.removeItem('disabledIndex');
          }
        }, 1000);
        return () => clearInterval(countdown);
      } else {
        localStorage.removeItem('expirationTime');
        localStorage.removeItem('disabledIndex');
      }
    }
  }, []);

  const handleOpenModal = (title, item, url, index) => {
    setModalTitle(title)
    setModalContent(item);
    setModalUrl(url);
    setModalOpen(true);
    setRedeemCode('');
    setCurrentItemIndex(index);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent('');
    setModalUrl('');
    setModalTitle('');
    setRedeemCode('');
    setCurrentItemIndex(null);
  };

  const handleClaimReward = async (e) => {
    e.preventDefault();
    //alert(`Redeem code claimed: ${redeemCode}`);
    //setRedeemCode('');

    const username = await user.username;
    const newPoints = 500; // Example point value

    const userData = {
      username,
      redeemCode,
      modalUrl,
      newPoints
    };
    console.log(userData)
    try {
      const response = await fetch('https://ayomitech.com/earncoin/api-php/redeem.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });
      const result = await response.json();

      if (result.status === 'success') {
          
        //console.log(result.status);
        
       

            if (currentItemIndex !== null) {
              const newDisabledItems = [...disabledItems];
              newDisabledItems[currentItemIndex] = true;
              setDisabledItems(newDisabledItems);
        
              // Calculate expiration time
              const expirationTime = Date.now() + 2000 * 60 * 60;
              localStorage.setItem('expirationTime', expirationTime);
              localStorage.setItem('disabledIndex', currentItemIndex);
        
              // Re-enable the button after 4 seconds
              setTimeout(() => {
                const updatedDisabledItems = [...newDisabledItems];
                updatedDisabledItems[currentItemIndex] = false;
                setDisabledItems(updatedDisabledItems);
                localStorage.removeItem('expirationTime');
                localStorage.removeItem('disabledIndex');
              }, 2 * 60 * 60);
            }
    
        handleCloseModal();
        setTimeout(() => {
          setMessage('Point Claim');
        }, 3000);
         
      } else {
        setMessage('Invalid Redeem Code');

        setTimeout(() => {
          setMessage('');
        }, 3000);
         console.log(result.status);
      }

    } catch (error) {
      //console.log('An error occurred:', error);
    
    }
  

    
  };

  return (
    <div className="task-container">
      <header className="header">
     
                 <div className="user-stats">
                  <span className="title-point">Points: {data?.points}</span>
                  <span className="title-task">Tasks Completed: {data?.totaltask}</span>
                </div>
           <div className="task-title">
            <span className="balance-label">Daily Task</span>
          </div>
          <span style={{color:'green'}}>{message}</span>
      </header>

     
  
      
        

      <div className="grid">
        <div className="grid-item">
          <img src={youtubeLogo} alt="Item 1" className="grid-image" />
          <h5>Subscribe</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 
              'Subcribe to YouTube Channel & Watch Video for 1 to 3mins.',
               'https://www.youtube.com/channel/UCteCvHE8wVXehlIqB05uwzA?sub_confirmation=1', 0)} 
            disabled={disabledItems[0]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={telegramLogo} alt="Item 2" className="grid-image" />
          <h5>Telegram</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('Telegram','Join Telegram Channel', 'https://t.me/fortuneclick', 1)} 
            disabled={disabledItems[1]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={tiktokLogo} alt="Item 3" className="grid-image" />
          <h5>Tiktok</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('Tiktok', 'Follow us on tiktok',
               'https://www.tiktok.com/@fortuneclick1280?is_from_webapp=1&sender_device=pc', 2)} 
            disabled={disabledItems[2]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={youtubeLogo} alt="Item 4" className="grid-image" />
          <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 3mins.', 'https://youtu.be/9AP2Sg8Uo-E', 3)} 
            disabled={disabledItems[3]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
        <img src={youtubeLogo} alt="Item 4" className="grid-image" />
        <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 3mins.', 'https://youtu.be/ezzv8HERYMU', 4)} 
            disabled={disabledItems[4]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
        <img src={youtubeLogo} alt="Item 4" className="grid-image" />
        <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 3mins.', 'https://youtu.be/EVu2NHnFIFc', 5)} 
            disabled={disabledItems[5]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
        <img src={youtubeLogo} alt="Item 4" className="grid-image" />
        <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 1 to 3mins.', 'https://youtu.be/Fy1GVweJHVA', 6)} 
            disabled={disabledItems[6]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
        <img src={youtubeLogo} alt="Item 4" className="grid-image" />
        <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 1 to 3mins.', 'https://youtu.be/Ou--k7i3dUM', 7)} 
            disabled={disabledItems[7]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={youtubeLogo} alt="Item 9" className="grid-image" />
          <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 1 to 3mins.', 'https://youtu.be/OoZybQmHHGA', 8)} 
            disabled={disabledItems[8]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={youtubeLogo} alt="Item 10" className="grid-image" />
          <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 1 to 3mins.', 'https://youtu.be/PicmvEHz2W0', 9)} 
            disabled={disabledItems[9]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={youtubeLogo} alt="Item 11" className="grid-image" />
          <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 1 to 3mins.', 'https://youtu.be/xJW5OX9ZEU4', 10)} 
            disabled={disabledItems[10]}
          >
            Check
          </button>
        </div>
        <div className="grid-item">
          <img src={youtubeLogo} alt="Item 12" className="grid-image" />
          <h5>Watch +500</h5>
          <button 
            className="grid-button" 
            onClick={() => handleOpenModal('YouTube', 'Watch Video for 1 to 3mins.', 'https://youtu.be/RF8GKH-ixiQ', 11)} 
            disabled={disabledItems[11]}
          >
            Check
          </button>
        </div>
      </div>
     
     


        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>{modalTittle}</h2>
              <p style={{fontSize:12}}>{modalContent}</p>
              <p className='mo-content'> { modalTittle == 'Telegram' ? '' :  `Click the open link button to ${modalContent}. 
                Use the code on the ${modalTittle} Channel/Video to redeem your reward.`}
              </p>

              <button className="modal-button" onClick={() => {
                window.open(modalUrl, '_blank');
                //handleCloseModal();
              }}>
                Open Link
              </button>
            
               <span style={{color:'red'}}>{message}</span>
              <form onSubmit={handleClaimReward}>
                <input
                  type="text"
                  placeholder="Enter redeem code"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="redeem-input"
                  required
                />
                <button type="submit" className="modal-button claim-button">Claim Reward</button>
              </form>

              
              <button className="modal-button-close" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        )}
    

      
        <div className='tabs'>
              <div
                className={`tab ${activeTab === 'board' ? 'active' : ''}`}
                onClick={() => handleTabClick('board')}
              >
                Home
              </div>
              <div
                className={`tab ${activeTab === 'task' ? 'active' : ''}`}
                onClick={() => handleTabClick('task')}
              >
                Task
              </div>
              <div
                className={`tab ${activeTab === 'invite' ? 'active' : ''}`}
                onClick={() => handleTabClick('invite')}
              >
                Refer
              </div>
              <div
                className={`tab ${activeTab === 'wallet' ? 'active' : ''}`}
                onClick={() => handleTabClick('wallet')}
              >
                Wallet
              </div>
            </div>
    
     
    </div>
  );
};

export default TaskPage;
