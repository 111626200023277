import React, { useState, useEffect } from 'react';

const TextToSpeech = () => {
  const [text, setText] = useState('');
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('');
  const [rate, setRate] = useState(1);
  const [pitch, setPitch] = useState(1);
  const [audioUrl, setAudioUrl] = useState('');

  useEffect(() => {
    const synth = window.speechSynthesis;

    const getVoices = () => {
      const availableVoices = synth.getVoices();
      setVoices(availableVoices);
      if (availableVoices.length > 0 && !selectedVoice) {
        setSelectedVoice(availableVoices[0].name);
      }
    };

    getVoices();
    synth.onvoiceschanged = getVoices;

    return () => {
      synth.onvoiceschanged = null;
    };
  }, [selectedVoice]);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSpeak = () => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = voices.find(voice => voice.name === selectedVoice);
    utterance.rate = rate;
    utterance.pitch = pitch;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const destination = audioContext.createMediaStreamDestination();
    const recorder = new MediaRecorder(destination.stream);
    const audioChunks = [];

    // Create a new audio element to capture the speech
    const audioElement = new Audio();
    audioElement.srcObject = destination.stream;
    audioElement.play();

    utterance.onstart = () => {
      recorder.start();
      window.speechSynthesis.speak(utterance);
    };

    utterance.onend = () => {
      recorder.stop();
      audioElement.srcObject = null; // Disconnect audio
    };

    recorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    recorder.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    };

    // Start speaking the text
    window.speechSynthesis.speak(utterance);
  };

  const handleDownload = () => {
    const a = document.createElement('a');
    a.href = audioUrl;
    a.download = 'speech.wav';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Text to Speech</h1>
      <textarea
        rows="5"
        cols="30"
        value={text}
        onChange={handleTextChange}
        placeholder="Type something..."
      />
      <br />
      <label>
        Voice:
        <select value={selectedVoice} onChange={(e) => setSelectedVoice(e.target.value)}>
          {voices.map(voice => (
            <option key={voice.name} value={voice.name}>
              {voice.name} ({voice.lang})
            </option>
          ))}
        </select>
      </label>
      <br />
      <label>
        Rate:
        <input
          type="number"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
          min="0.1"
          max="10"
          step="0.1"
        />
      </label>
      <br />
      <label>
        Pitch:
        <input
          type="number"
          value={pitch}
          onChange={(e) => setPitch(e.target.value)}
          min="0"
          max="2"
          step="0.1"
        />
      </label>
      <br />
      <button onClick={handleSpeak} disabled={!text}>
        Speak
      </button>
      {audioUrl && (
        <div>
          <audio controls src={audioUrl} />
          <button onClick={handleDownload}>Download Speech</button>
        </div>
      )}
    </div>
  );
};

export default TextToSpeech;
